.App {
  font-family: sans-serif;
  text-align: center;
}

.tablerow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: start;
  align-items: start;
}
